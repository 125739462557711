import React from 'react';
import {Layout} from 'components/pageLayouts';
import {First} from '3D';

const Three = () => {
	return (
		<Layout>
			<First />
		</Layout>
	);
};

export default Three;
